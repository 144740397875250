export function changeOpacity(id, opacity) {
  setTimeout(function () {
    const el = document.getElementById(id);
    el.style["opacity"] = opacity;
  }, 10);
}

export function getCookie(name) {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function setCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}
export function debounce(callback, wait) {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

export function viewport() {
  var e = window,
    a = "inner";
  if (!("innerWidth" in window)) {
    a = "client";
    e = document.documentElement || document.body;
  }
  return { width: e[a + "Width"], height: e[a + "Height"] };
}

export function getNext(direction, i, min, max) {
  let next = direction === "up" ? i + 1 : i - 1;

  if (next > max) {
    next = min;
  }

  if (next < min) {
    next = max;
  }

  return next;
}
function switchToggle(id) {
  const consent = document.getElementById(id);
  const value = consent.classList.contains("justify-end") ? "1" : "0";

  if (value === "1") {
    consent.classList.remove(
      "justify-end",
      "bg-green-base",
      "border-green-dark"
    );
    consent.classList.add("bg-gray-light", "border-gray-base");
  } else {
    consent.classList.add("justify-end", "bg-green-base", "border-green-dark");
    consent.classList.remove("bg-gray-light", "border-gray-base");
  }
}

window.switchToggle = switchToggle;
